 <template>
    <v-card>
        <div class="px-4 pt-2 dictionary__header">
            <span class="title font-weight-light">Słownik: Opcje - nazwy</span>
            <v-menu
                :close-on-content-click="false"
                v-model="getOptionNameModal.open"
                max-width="500"
                origin="center"
                @input="v => v || clearOptionName()"
            >
                <template v-slot:activator="{ on }">
                    <v-btn @click="setCreateOptionName('create')" icon v-on="on">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </template>
                <v-card>
                    <p
                        class="display-1 py-3 text-center ma-0 font-weight-light"
                    >{{ getOptionNameType === 'create' ? 'Dodawanie' : 'Edycja' }}</p>
                    <v-divider></v-divider>
                    <v-container fluid class="py-0 px-5">
                        <v-row>
                            <v-col class="py-0" cols="12">
                                <v-text-field
                                    v-model="getOptionName.optionName"
                                    :error-messages="getOptionErrors.optionName"
                                    label="Nazwa"
                                ></v-text-field>
                            </v-col>
                            <v-col md="3" offset-md="9">
                                <v-btn
                                    @click="createOptionName"
                                    class="buttons mr-5 buttons--add"
                                    v-if="getOptionNameType == 'create'"
                                >Dodaj</v-btn>
                                <v-btn
                                    v-else
                                    class="buttons mr-5 buttons--add"
                                    @click="OptionNameUpdate"
                                >Edytuj</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-menu>
        </div>

        <v-simple-table height="250" fixed-header>
            <thead>
                <tr>
                    <th>Nazwa</th>
                    <th class="text-right">Akcje</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="option in getOptionNameItems" :key="option._id">
                    <td>{{ option.optionName }}</td>
                    <td class="text-right">
                        <v-btn
                            @click="OptionNameDeatils(option._id), setCreateOptionName('edit')"
                            icon
                            x-small
                        >
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn @click="deleteOptions(option._id)" icon x-small>
                            <v-icon class="red--text">mdi-delete</v-icon>
                        </v-btn>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
    </v-card>
</template>
<script>
import Vue from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
    computed: {
        ...mapGetters([
            "getOptionName",
            "getOptionNameItems",
            "getOptionNameModal",
            "getOptionErrors",
            "getOptionNameType"
        ])
    },
    methods: {
        ...mapActions([
            "createOptionName",
            "fetchOptionName",
            "openSecondModal",
            "OptionNameDeatils",
            "OptionNameUpdate"
        ]),
        ...mapMutations(["setCreateOptionName", "clearOptionName"]),

        deleteOptions(DOC) {
            this.openSecondModal({
                width: 500,
                open: true,
                data: { DOC },
                type: "dictionary_options"
            });
        }
    },
    beforeMount() {
        this.fetchOptionName();
    }
};
</script>