<template>
    <v-card>
        <div class="px-4 pt-2 dictionary__header">
            <span class="title font-weight-light">Słownik: Producent / Marka</span>
            <v-menu
                :close-on-content-click="false"
                max-width="500"
                origin="center"
                v-model="getManufacturerBrand.open"
                @input="v => v || clearManufacturerBrand()"
            >
                <template v-slot:activator="{on}">
                    <v-btn icon v-on="on" @click="setManufacturerBrandType('create')">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </template>
                <v-card>
                    <p
                        class="display-1 py-3 text-center ma-0 font-weight-light"
                    >{{getManufacturerBrandType === 'create' ? 'Dodawanie' : 'Edycja'}}</p>
                    <v-divider></v-divider>
                    <v-container fluid class="py-0">
                        <v-row>
                            <v-col class="py-0" cols="12">
                                <v-text-field
                                    label="Nazwa"
                                    v-model="getManufacturerBrand.name"
                                    :error-messages="getManufacturerBrandErrors.name"
                                ></v-text-field>
                            </v-col>
                            <v-col md="3" offset-md="8">
                                <v-btn
                                    class="buttons buttons--add"
                                    @click="createManufacturerBrand"
                                    v-if="getManufacturerBrandType == 'create'"
                                >Dodaj</v-btn>
                                <v-btn
                                    class="buttons buttons--add"
                                    @click="ManufacturerBrandUpdate"
                                    v-else
                                >Edytuj</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-menu>
        </div>

        <v-simple-table height="250" fixed-header>
            <thead>
                <tr>
                    <th>Nazwa</th>
                    <th class="text-right">Akcje</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="mb in getManufacturerBrandItems" :key="mb._id">
                    <td>{{mb.name}}</td>
                    <td class="text-right">
                        <v-btn
                            icon
                            x-small
                            @click="ManufacturerBrandDetails(mb._id), setManufacturerBrandType('edit')"
                        >
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn icon x-small @click="deleteMb(mb._id)">
                            <v-icon class="red--text">mdi-delete</v-icon>
                        </v-btn>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
    </v-card>
</template>
<script>
import Vue from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
    data: () => ({}),
    computed: {
        ...mapGetters([
            "getManufacturerBrand",
            "getManufacturerBrandItems",
            "getManufacturerBrandErrors",
            "getManufacturerBrandType"
        ])
    },
    methods: {
        ...mapActions([
            "createManufacturerBrand",
            "fetchManufacturerBrand",
            "deleteManufacturerBrand",
            "ManufacturerBrandDetails",
            "ManufacturerBrandUpdate",
            "openSecondModal"
        ]),
        ...mapMutations(["clearManufacturerBrand", "setManufacturerBrandType"]),
        deleteMb(MID) {
            this.openSecondModal({
                width: 500,
                open: true,
                data: { MID },
                type: "dictionary_manufacturer_brand"
            });
        }
    },
    beforeMount() {
        this.fetchManufacturerBrand();
    }
};
</script>