<template>
    <v-card>
        <div class="px-4 pt-2 dictionary__header">
            <span class="title font-weight-light">Słownik: Podstawa serwisu</span>
            <v-menu
                :close-on-content-click="false"
                max-width="500"
                origin="center"
                v-model="getServiceCore.open"
                @input="v => v || clearServiceCore()"
            >
                <template v-slot:activator="{on}">
                    <v-btn icon v-on="on" @click="setServiceCoreType('create')">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </template>
                <v-card>
                    <p
                        class="display-1 py-3 text-center ma-0 font-weight-light"
                    >{{ getServiceCoreType === 'create' ? 'Dodawanie' : 'Edycja' }}</p>
                    <v-divider></v-divider>
                    <v-container fluid class="py-0 px-5">
                        <v-row>
                            <v-col class="py-0" cols="12">
                                <v-text-field
                                    label="Nazwa"
                                    v-model="getServiceCore.serviceCoreName"
                                    :error-messages="getServiceCoreErrors.serviceCoreName"
                                ></v-text-field>
                            </v-col>
                            <v-col md="3" offset-md="9">
                                <v-btn
                                    class="buttons buttons--add"
                                    @click="createServiceCore"
                                    v-if="getServiceCoreType == 'create'"
                                >Dodaj</v-btn>
                                <v-btn
                                    class="buttons buttons--add"
                                    @click="ServiceCoreUpdate"
                                    v-else
                                >Edytuj</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-menu>
        </div>

        <v-simple-table height="250" fixed-header>
            <thead>
                <tr>
                    <th>Nazwa</th>
                    <th class="text-right">Akcje</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="sc in getServiceCoreItems" :key="sc._id">
                    <td>{{ sc.serviceCoreName }}</td>
                    <td class="text-right">
                        <v-btn
                            icon
                            @click="ServiceCoreDetails(sc._id), setServiceCoreType('edit')"
                            x-small
                        >
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn icon x-small @click="deleteSc(sc._id)">
                            <v-icon class="red--text">mdi-delete</v-icon>
                        </v-btn>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
    </v-card>
</template>
<script>
import Vue from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
    data: () => ({}),
    computed: {
        ...mapGetters([
            "getServiceCore",
            "getServiceCoreItems",
            "getServiceCoreErrors",
            "getServiceCoreType"
        ])
    },
    methods: {
        ...mapActions([
            "createServiceCore",
            "fetchServiceCore",
            "deleteServiceCore",
            "ServiceCoreDetails",
            "ServiceCoreUpdate",
            "openSecondModal"
        ]),
        ...mapMutations(["clearServiceCore", "setServiceCoreType"]),
        deleteSc(SCID) {
            this.openSecondModal({
                width: 500,
                open: true,
                data: { SCID },
                type: "dictionary_service_core"
            });
        }
    },
    beforeMount() {
        this.fetchServiceCore();
    }
};
</script>
