<template>
    <v-card>
        <div class="px-4 pt-2 dictionary__header">
            <span class="title font-weight-light">Pracownicy: stanowisko</span>
            <v-menu
                :close-on-content-click="false"
                max-width="500"
                v-model="getUJob.open"
                @input="v => v || clearUJob()"
            >
                <template v-slot:activator="{on}">
                    <v-btn icon v-on="on" @click="setUJobType('create')">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </template>
                <v-card>
                    <p
                        class="display-1 py-3 text-center ma-0 font-weight-light"
                    >{{getUJobType === 'create' ? 'Dodawanie' : 'Edycja'}}</p>
                    <v-divider></v-divider>
                    <v-container fluid class="py-0">
                        <v-row>
                            <v-col md="12">
                                <v-text-field
                                    v-model="getUJob.jobName"
                                    :error-messages="getUJobErrors.jobName"
                                    label="Nazwa"
                                ></v-text-field>
                            </v-col>
                            <v-col md="3" offset-md="8">
                                <v-btn
                                    class="buttons buttons--add"
                                    v-if="getUJobType == 'create'"
                                    @click="createUJob"
                                >Dodaj</v-btn>
                                <v-btn
                                    class="buttons buttons--add"
                                    @click="uJobUpdate"
                                    v-else
                                >Edytuj</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-menu>
        </div>
        <v-simple-table height="250" fixed-header>
            <thead>
                <tr>
                    <th>Nazwa</th>
                    <th>Akcje</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="job in getUJobItems" :key="job._id">
                    <td>{{job.jobName}}</td>
                    <td>
                        <v-btn icon x-small @click="UJobDetails(job._id),setUJobType('edit')">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn icon x-small @click="deleteUserJob(job._id)">
                            <v-icon class="red--text">mdi-delete</v-icon>
                        </v-btn>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
    </v-card>
</template>
<script>
import Vue from "vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
    data: () => ({}),
    computed: {
        ...mapGetters([
            "getUJob",
            "getUJobType",
            "getUJobItems",
            "getUJobErrors"
        ])
    },
    methods: {
        ...mapMutations(["setUJobType", "clearUJob"]),
        ...mapActions([
            "createUJob",
            "deleteUJob",
            "fetchUJob",
            "UJobDetails",
            "openSecondModal",
            "uJobUpdate"
        ]),
        deleteUserJob(JID) {
            // Vue.swal({
            //     title: "Jesteś pewny?",
            //     text: "Można to później przywrócić",
            //     type: "warning",
            //     showCancelButton: true,
            //     confirmButtonText: "Usuń",
            //     cancelButtonText: "Anuluj",
            //     reverseButtons: true
            // }).then(result => {
            //     if (result.value) {
            //         this.deleteUJob(JID);
            //     }
            // });
            this.openSecondModal({
                width: 500,
                open: true,
                data: { JID },
                type: "dictionary_user_job"
            });
        }
    },
    beforeMount() {
        this.fetchUJob();
    }
};
</script>