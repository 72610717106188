<template>
    <v-card>
        <div class="px-4 pt-2 dictionary__header">
            <span class="title font-weight-light">Słownik: Samochody / Model</span>
            <v-menu
                :close-on-content-click="false"
                v-model="getCarsOpen.open"
                max-width="500"
                origin="center"
                @input="v => v || clearModelCar()"
            >
                <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" @click="setModelCarDeatils('create')">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </template>
                <v-card>
                    <p
                        class="display-1 py-3 text-center ma-0 font-weight-light"
                    >{{getCarsType === 'create' ? 'Dodawanie' : 'Edycja'}}</p>
                    <v-divider></v-divider>
                    <v-container fluid class="py-0 px-5">
                        <v-row>
                            <v-col class="py-0" cols="12">
                                <v-text-field
                                    v-model="getCarsDictionary.carModelname"
                                    :error-messages="getCarsErr.carModelname"
                                    label="Nazwa"
                                ></v-text-field>
                            </v-col>
                            <v-col md="3" offset-md="9">
                                <v-btn
                                    class="buttons mr-5 buttons--add"
                                    @click="createModelCar"
                                    v-if="getCarsType == 'create'"
                                >Dodaj</v-btn>
                                <v-btn
                                    v-else
                                    class="buttons mr-5 buttons--add"
                                    @click="ModelCarUpdate"
                                >Edytuj</v-btn>
                                <!-- <v-btn class="buttons buttons--add">Edytuj</v-btn> -->
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-menu>
        </div>

        <v-simple-table height="250" fixed-header>
            <thead>
                <tr>
                    <th>Nazwa</th>
                    <th class="text-right">Akcje</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="models in getCarsDictionaryItems" :key="models._id">
                    <td>{{ models.carModelname }}</td>
                    <td class="text-right">
                        <v-btn
                            icon
                            @click="
                ModelCarDeatils(models._id), setModelCarDeatils('edit')
              "
                            x-small
                        >
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn icon x-small @click="deleteModel(models._id)">
                            <v-icon class="red--text">mdi-delete</v-icon>
                        </v-btn>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
    </v-card>
</template>
<script>
import Vue from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
    computed: {
        ...mapGetters([
            "getCarsDictionary",
            "getCarsDictionaryItems",
            "getCarsOpen",
            "getCarsErr",
            "getCarsType"
        ])
    },
    methods: {
        ...mapActions([
            "createModelCar",
            "fetchModelCar",
            "openSecondModal",
            "ModelCarDeatils",
            "ModelCarUpdate"
        ]),
        ...mapMutations([
            "setCarsDetailsDictionary",
            "setModelCarDeatils",
            "clearModelCar"
        ]),
        deleteModel(CMDD) {
            this.openSecondModal({
                width: 500,
                open: true,
                data: { CMDD },
                type: "dictionary_cars_models"
            });
        }
    },
    beforeMount() {
        this.fetchModelCar();
    }
};
</script>
