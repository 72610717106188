<template>
  <v-card>
    <div class="px-4 pt-2 dictionary__header">
      <span class="title font-weight-light">Słownik: Pakiet usług</span>
      <v-menu
        :close-on-content-click="false"
        max-width="700"
        min-width="700"
        origin="center"
        v-model="getSPrice.open"
        @input="v => v || clearSPrice()"
      >
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="setSPrice('create')">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <v-card>
          <p
            class="display-1 py-3 text-center ma-0 font-weight-light"
          >{{ getSPriceType === "create" ? "Dodawanie" : "Edycja" }}</p>
          <v-divider></v-divider>
          <v-container fluid class="py-0 px-5">
            <v-row>
              <v-col class="py-0" cols="12">
                <v-text-field
                  label="Nazwa"
                  :error-messages="getSPriceErrors.priceName"
                  v-model="getSPrice.priceName"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="pt-0">
                <v-textarea
                  label="Opis"
                  rows="4"
                  v-model="getSPrice.description"
                  :error-messages="getSPriceErrors.description"
                ></v-textarea>
              </v-col>
              <v-col cols="4" class="py-0" v-if="getSPriceType !== 'create'">
                <!-- <div class="input-effect price">
                                    <span>Przegląd okresowy</span>
                                    <money
                                        class="effect-17"
                                        v-model="getSPrice.survey.price"
                                        v-bind="money"
                                    ></money>
                                    <span class="focus-border"></span>
                </div>-->
                <v-text-field label="Przegląd okresowy" v-model="getSPrice.survey.price"></v-text-field>
              </v-col>
              <v-col cols="2" v-if="getSPriceType !== 'create'">
                <v-select dense label="Jednostka" v-model="getSPrice.survey.value" :items="units"></v-select>
              </v-col>
              <v-col cols="4" class="py-0" v-if="getSPriceType !== 'create'">
                <div class="input-effect price">
                  <span>Naprawa</span>
                  <money class="effect-17" v-model="getSPrice.repair.price" v-bind="money"></money>
                  <span class="focus-border"></span>
                </div>
              </v-col>
              <v-col cols="2" v-if="getSPriceType !== 'create'">
                <v-select dense label="Jednostka" :items="units" v-model="getSPrice.repair.value"></v-select>
              </v-col>
              <v-col cols="4" v-if="getSPriceType !== 'create'">
                <v-text-field
                  dense
                  label="Wdrożenie/Instalacja"
                  v-model="getSPrice.installation.price"
                ></v-text-field>
              </v-col>
              <!-- <v-col cols="4" class="py-0" v-if="getSPriceType !== 'create'">
                                <div class="input-effect price">
                                    <span>Wdrożenie/Instalacja</span>
                                    <money
                                        class="effect-17"
                                        v-model="getSPrice.installation.price"
                                        v-bind="money"
                                    ></money>
                                    <span class="focus-border"></span>
                                </div>
              </v-col>-->
              <v-col cols="2" v-if="getSPriceType !== 'create'">
                <v-select
                  dense
                  label="Jednostka"
                  :items="units"
                  v-model="getSPrice.installation.value"
                ></v-select>
              </v-col>
              <!-- <v-col cols="4" v-if="getSPriceType !== 'create'">
                                <v-text-field
                                    dense
                                    label="Diagnostyka"
                                    v-model="getSPrice.diagnostics.price"
                                ></v-text-field>
              </!-->

              <v-col cols="4" class="py-0" v-if="getSPriceType !== 'create'">
                <div class="input-effect price">
                  <span>Diagnostyka</span>
                  <money class="effect-17" v-model="getSPrice.diagnostics.price" v-bind="money"></money>
                  <span class="focus-border"></span>
                </div>
              </v-col>
              <v-col cols="2" v-if="getSPriceType !== 'create'">
                <v-select
                  dense
                  label="Jednostka"
                  :items="units"
                  v-model="getSPrice.diagnostics.value"
                ></v-select>
              </v-col>
              <!-- <v-col cols="4" v-if="getSPriceType !== 'create'">
                                <v-text-field
                                    dense
                                    label="Usługa IT"
                                    v-model="getSPrice.itService.price"
                                ></v-text-field>
              </v-col>-->
              <v-col cols="4" class="py-0" v-if="getSPriceType !== 'create'">
                <div class="input-effect price">
                  <span>Usługa IT</span>
                  <money class="effect-17" v-model="getSPrice.itService.price" v-bind="money"></money>
                  <span class="focus-border"></span>
                </div>
              </v-col>
              <v-col cols="2" v-if="getSPriceType !== 'create'">
                <v-select
                  dense
                  label="Jednostka"
                  :items="units"
                  v-model="getSPrice.itService.value"
                ></v-select>
              </v-col>
              <!-- <v-col cols="4" v-if="getSPriceType !== 'create'">
                                <v-text-field
                                    dense
                                    label="Usługa zdalna"
                                    v-model="getSPrice.remote.price"
                                ></v-text-field>
              </v-col>-->
              <v-col cols="4" class="py-0" v-if="getSPriceType !== 'create'">
                <div class="input-effect price">
                  <span>Usługa zdalna</span>
                  <money class="effect-17" v-model="getSPrice.remote.price" v-bind="money"></money>
                  <span class="focus-border"></span>
                </div>
              </v-col>
              <v-col cols="2" v-if="getSPriceType !== 'create'">
                <v-select dense label="Jednostka" v-model="getSPrice.remote.value" :items="units"></v-select>
              </v-col>
              <v-col md="3" offset-md="9" style="display: flex; justify-content: flex-end">
                <v-btn
                  class="buttons buttons--add"
                  v-if="getSPriceType == 'create'"
                  @click="createSPrice"
                >Dodaj</v-btn>
                <v-btn v-else class="buttons buttons--add" @click="updateSPrice">Edytuj</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-menu>
    </div>

    <v-simple-table height="250" fixed-header>
      <thead>
        <tr>
          <th>Nazwa</th>
          <th>Opis</th>
          <th class="text-right">Akcje</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="price in getSPriceItems" :key="price._id">
          <td>{{ price.priceName }}</td>
          <td>{{ price.description }}</td>
          <td class="text-right">
            <v-btn icon @click="SPDetails(price._id), setSPrice('edit')" x-small>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon x-small @click="removePrice(price._id)">
              <v-icon class="red--text">mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-card>
</template>
<script>
import Vue from "vue";
import { Money } from "v-money";
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  data: () => ({
    units: ["za szt.", "za rbh"],
    price: "12,50",
    money: {
      decimal: ".",
      thousands: "",
      suffix: " PLN",
      precision: 2,
      masked: false
    }
  }),
  computed: {
    ...mapGetters([
      "getSPrice",
      "getSPriceType",
      "getSPriceItems",
      "getSPriceErrors"
    ])
  },
  methods: {
    ...mapMutations(["clearSPrice", "setSPrice"]),
    ...mapActions([
      "createSPrice",
      "fetchSPrice",
      "openSecondModal",
      "SPDetails",
      "updateSPrice"
    ]),
    removePrice(PID) {
      this.openSecondModal({
        width: 500,
        open: true,
        data: { PID },
        type: "dictionary_price"
      });
    }
  },
  components: {
    Money
  },
  beforeMount() {
    this.fetchSPrice();
  }
};
</script>
