<template>
    <v-card>
        <div class="px-4 pt-2 dictionary__header">
            <span class="title font-weight-light">Pracownicy: podstawa zatrudnienia</span>
            <v-menu
                :close-on-content-click="false"
                max-width="500"
                origin="center"
                v-model="getUContract.open"
                @input="v => v || clearUContract()"
            >
                <template v-slot:activator="{on}">
                    <v-btn icon v-on="on" @click="setUContractType('create')">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </template>
                <v-card>
                    <p
                        class="display-1 py-3 text-center ma-0 font-weight-light"
                    >{{getUContractType === 'create' ? 'Dodawanie' : 'Edycja'}}</p>
                    <v-divider></v-divider>
                    <v-container fluid class="py-0">
                        <v-row>
                            <v-col md="12">
                                <v-text-field
                                    v-model="getUContract.contractName"
                                    :error-messages="getUContractErrors.contractName"
                                    label="Nazwa"
                                ></v-text-field>
                            </v-col>
                            <v-col md="3" offset-md="8">
                                <v-btn
                                    class="buttons buttons--add"
                                    v-if="getUContractType == 'create'"
                                    @click="createUContract"
                                >Dodaj</v-btn>
                                <v-btn
                                    class="buttons buttons--add"
                                    @click="UContractUpdate"
                                    v-else
                                >Edytuj</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-menu>
        </div>
        <v-simple-table height="250" fixed-header>
            <thead>
                <tr>
                    <th>Nazwa</th>
                    <th>Akcje</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="contract in getUContractItems" :key="contract._id">
                    <td>{{contract.contractName}}</td>
                    <td>
                        <v-btn
                            icon
                            x-small
                            @click="UContractDetails(contract._id), setUContractType('edit')"
                        >
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn icon x-small @click="deleteContract(contract._id)">
                            <v-icon class="red--text">mdi-delete</v-icon>
                        </v-btn>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
    </v-card>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
    computed: {
        ...mapGetters([
            "getUContract",
            "getUContractType",
            "getUContractErrors",
            "getUContractItems"
        ])
    },
    methods: {
        ...mapMutations(["setUContractType", "clearUContract"]),
        ...mapActions([
            "createUContract",
            "fetchUContract",
            "deleteUContract",
            "UContractDetails",
            "UContractUpdate",
            "openSecondModal"
        ]),
        deleteContract(CID) {
            this.openSecondModal({
                width: 500,
                open: true,
                data: { CID },
                type: "dictionary_user_contract"
            });
        }
    },
    beforeMount() {
        this.fetchUContract();
    }
};
</script>