<template>
    <v-card>
        <div class="px-4 pt-2 dictionary__header">
            <span class="title font-weight-light">Zadania: priorytety</span>
            <v-menu
                :close-on-content-click="false"
                max-width="500"
                origin="center"
                v-model="getTPriority.open"
                @input="v => v || clearTPriority()"
            >
                <template v-slot:activator="{on}">
                    <v-btn icon v-on="on" @click="setTPriorityType('create')">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </template>
                <v-card>
                    <p
                        class="display-1 py-3 text-center ma-0 font-weight-light"
                    >{{getTPriorityType === 'create' ? 'Dodawanie' : 'Edycja'}}</p>
                    <v-divider></v-divider>
                    <v-container fluid class="py-0">
                        <v-row>
                            <v-col md="12">
                                <v-text-field
                                    v-model="getTPriority.priorityName"
                                    :error-messages="getPriorityErrors.priorityName"
                                    label="Nazwa"
                                ></v-text-field>
                            </v-col>
                            <v-col md="6">
                                <span class="grey--text darken-4">Tło:</span>
                                <v-color-picker v-model="getTPriority.background" hide-inputs></v-color-picker>
                            </v-col>
                            <v-col md="6">
                                <span class="grey--text darken-4">Czcionka:</span>
                                <v-color-picker v-model="getTPriority.color" hide-inputs></v-color-picker>
                            </v-col>
                            <v-col md="3" offset-md="9">
                                <v-btn
                                    class="buttons buttons--add"
                                    v-if="getTPriorityType == 'create'"
                                    @click="createTPriority"
                                >Dodaj</v-btn>
                                <v-btn
                                    class="buttons buttons--add"
                                    @click="TPriorityUpdate"
                                    v-else
                                >Edytuj</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-menu>
        </div>
        <v-simple-table height="250" fixed-header>
            <thead>
                <tr>
                    <th>Priorytet</th>
                    <th>Tło</th>
                    <th>Czcionka</th>
                    <th>Akcje</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="priority in getTPriorityItems" :key="priority._id">
                    <td>{{priority.priorityName}}</td>
                    <td>
                        <v-chip
                            label
                            :color="priority.background"
                            :style="`color: ${priority.color}`"
                            small
                        >{{priority.background}}</v-chip>
                    </td>
                    <td>
                        <v-chip
                            label
                            :color="priority.background"
                            :style="`color: ${priority.color}`"
                            small
                        >{{priority.color}}</v-chip>
                    </td>
                    <td>
                        <v-btn
                            icon
                            x-small
                            @click="TPriorityDetails(priority._id), setTPriorityType('edit')"
                        >
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn icon x-small @click="deletePriority(priority._id)">
                            <v-icon class="red--text">mdi-delete</v-icon>
                        </v-btn>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
    </v-card>
</template>
<script>
import Vue from "vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
    data: () => ({}),
    computed: {
        ...mapGetters([
            "getTPriority",
            "getTPriorityType",
            "getTPriorityItems",
            "getPriorityErrors"
        ])
    },
    methods: {
        ...mapMutations(["setTPriorityType", "clearTPriority"]),
        ...mapActions([
            "createTPriority",
            "fetchTPriority",
            "deleteTPriority",
            "TPriorityDetails",
            "TPriorityUpdate",
            "openSecondModal"
        ]),
        deletePriority(PID) {
            this.openSecondModal({
                width: 500,
                open: true,
                data: { PID },
                type: "dictionary_tasks_priority"
            });
            // Vue.swal({
            //     title: "Jesteś pewny?",
            //     text: "Można to później przywrócić",
            //     type: "warning",
            //     showCancelButton: true,
            //     confirmButtonText: "Usuń",
            //     cancelButtonText: "Anuluj",
            //     reverseButtons: true
            // }).then(result => {
            //     if (result.value) {
            //         this.deleteTPriority(PID);
            //     }
            // });
        }
    },
    mounted() {
        this.fetchTPriority();
    }
};
</script>