<template>
    <v-card>
        <div class="px-4 pt-2 dictionary__header">
            <span class="title font-weight-light">Słownik: Rodzaj</span>
            <v-menu
                :close-on-content-click="false"
                max-width="1500"
                origin="center"
                v-model="getServiceType.open"
                @input="v => v || clearServiceType()"
            >
                <template v-slot:activator="{on}">
                    <v-btn icon v-on="on" @click="setServiceTypeType('create')">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </template>
                <v-card>
                    <p
                        class="display-1 py-3 text-center ma-0 font-weight-light"
                    >{{getServiceTypeType === 'create' ? 'Dodawanie' : 'Edycja'}}</p>
                    <v-divider></v-divider>
                    <v-container fluid class="py-0">
                        <v-row>
                            <v-col class="py-0" cols="12">
                                <v-text-field
                                    label="Nazwa"
                                    v-model="getServiceType.name"
                                    :error-messages="getServiceTypeErrors.name"
                                ></v-text-field>
                            </v-col>
                            <v-col md="3" offset-md="8">
                                <v-btn
                                    class="buttons buttons--add"
                                    @click="createServiceType"
                                    v-if="getServiceTypeType == 'create'"
                                >Dodaj</v-btn>
                                <v-btn
                                    class="buttons buttons--add"
                                    @click="ServiceTypeUpdate"
                                    v-else
                                >Edytuj</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-menu>
        </div>

        <v-simple-table height="250" fixed-header>
            <thead>
                <tr>
                    <th>Nazwa</th>
                    <th class="text-right">Akcje</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="st in getServiceTypeItems" :key="st._id">
                    <td>{{st.name}}</td>
                    <td class="text-right">
                        <v-btn
                            icon
                            x-small
                            @click="ServiceTypeDetails(st._id), setServiceTypeType('edit')"
                        >
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn icon x-small @click="deleteSt(st._id)">
                            <v-icon class="red--text">mdi-delete</v-icon>
                        </v-btn>
                        <!-- <v-btn @click="getSecondModal"></v-btn> -->
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
    </v-card>
</template>
<script>
import Vue from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
    data: () => ({}),
    computed: {
        ...mapGetters([
            "getServiceType",
            "getServiceTypeItems",
            "getServiceTypeErrors",
            "getServiceTypeType"
        ])
    },
    methods: {
        ...mapActions([
            "createServiceType",
            "fetchServiceType",
            "deleteServiceType",
            "ServiceTypeDetails",
            "ServiceTypeUpdate",
            "openSecondModal"
        ]),
        ...mapMutations(["clearServiceType", "setServiceTypeType"]),
        deleteSt(STID) {
            this.openSecondModal({
                width: 900,
                open: true,
                data: { STID },
                type: "dictionary_service_type"
            });
        }
    },
    beforeMount() {
        this.fetchServiceType();
    }
};
</script>