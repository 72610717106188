<template>
    <v-card>
        <div class="px-4 pt-2 dictionary__header">
            <span class="title font-weight-light">Zadania: kategoria</span>
            <v-menu
                :close-on-content-click="false"
                max-width="500"
                origin="center"
                v-model="getTCategory.open"
                @input="v => v || clearTCategory()"
            >
                <template v-slot:activator="{on}">
                    <v-btn icon v-on="on" @click="setTCategoryType('create')">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </template>
                <v-card>
                    <p
                        class="display-1 py-3 text-center ma-0 font-weight-light"
                    >{{getTCategoryType === 'create' ? 'Dodawanie' : 'Edycja'}}</p>
                    <v-divider></v-divider>
                    <v-container fluid class="py-0">
                        <v-row>
                            <v-col class="py-0" cols="12">
                                <v-text-field
                                    label="Nazwa"
                                    v-model="getTCategory.name"
                                    :error-messages="getTCategoryErrors.name"
                                ></v-text-field>
                            </v-col>
                            <v-col md="6">
                                <span class="grey--text darken-4">Tło:</span>
                                <v-color-picker v-model="getTCategory.background" hide-inputs></v-color-picker>
                            </v-col>
                            <v-divider vertical></v-divider>
                            <v-col md="6" style="margin-left: -1px">
                                <span class="grey--text darken-4">Czcionka:</span>
                                <v-color-picker v-model="getTCategory.color" hide-inputs></v-color-picker>
                            </v-col>
                            <v-col md="3" offset-md="9">
                                <v-btn
                                    class="buttons buttons--add"
                                    @click="createTCategory"
                                    v-if="getTCategoryType == 'create'"
                                >Dodaj</v-btn>
                                <v-btn
                                    class="buttons buttons--add"
                                    @click="TCategoryUpdate"
                                    v-else
                                >Edytuj</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-menu>
        </div>

        <v-simple-table height="250" fixed-header>
            <thead>
                <tr>
                    <th>Kategoria</th>
                    <th>Tło</th>
                    <th>Czcionka</th>
                    <th class="text-right">Akcje</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="category in getTCategoryItems" :key="category._id">
                    <td>{{category.name}}</td>
                    <td>
                        <v-chip
                            label
                            :color="category.background"
                            :style="`color: ${category.color}`"
                            small
                        >{{category.background}}</v-chip>
                    </td>
                    <td>
                        <v-chip
                            label
                            :color="category.background"
                            :style="`color: ${category.color}`"
                            small
                        >{{category.color}}</v-chip>
                    </td>
                    <td class="text-right">
                        <v-btn
                            icon
                            x-small
                            @click="TCategoryDetails(category._id), setTCategoryType('edit')"
                        >
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn icon x-small @click="deleteCategory(category._id)">
                            <v-icon class="red--text">mdi-delete</v-icon>
                        </v-btn>
                        <!-- <v-btn @click="getSecondModal"></v-btn> -->
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
    </v-card>
</template>
<script>
import Vue from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
    data: () => ({}),
    computed: {
        ...mapGetters([
            "getTCategory",
            "getTCategoryItems",
            "getTCategoryErrors",
            "getTCategoryType"
        ])
    },
    methods: {
        ...mapActions([
            "createTCategory",
            "fetchTCategory",
            "deleteTCategory",
            "TCategoryDetails",
            "TCategoryUpdate",
            "openSecondModal"
        ]),
        ...mapMutations(["clearTCategory", "setTCategoryType"]),
        deleteCategory(CID) {
            this.openSecondModal({
                width: 500,
                open: true,
                data: { CID },
                type: "dictionary_tasks_category"
            });
        }
    },
    beforeMount() {
        this.fetchTCategory();
    }
};
</script>