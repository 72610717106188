<template>
    <v-card>
        <div class="px-4 pt-2 dictionary__header">
            <span class="title font-weight-light">Słownik: Seria modeli</span>
            <v-menu
                v-model="getSeries.open"
                :close-on-content-click="false"
                max-width="500"
                origin="center"
                @input="v => v || clearMS()"
            >
                <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" @click="setMSType('create')">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </template>
                <v-card>
                    <p
                        class="display-1 py-3 text-center ma-0 font-weight-light"
                    >{{ getSeriesType === 'create' ? 'Dodawanie' : 'Edycja' }}</p>
                    <v-divider></v-divider>
                    <v-container fluid class="py-0 px-5">
                        <v-row>
                            <v-col class="py-0" cols="12">
                                <v-text-field
                                    label="Nazwa"
                                    v-model="getSeries.seriesName"
                                    :error-messages="getSeriesErrors.seriesName"
                                ></v-text-field>
                            </v-col>
                            <v-col md="3" offset-md="9">
                                <v-btn
                                    v-if="getSeriesType == 'create'"
                                    class="buttons mr-5 buttons--add"
                                    @click="createSeries"
                                >Dodaj</v-btn>
                                <v-btn
                                    v-else
                                    class="buttons mr-5 buttons--add"
                                    @click="MSUpdate"
                                >Edytuj</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-menu>
        </div>

        <v-simple-table height="250" fixed-header>
            <thead>
                <tr>
                    <th>Nazwa</th>
                    <th class="text-right">Akcje</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="series in getSeriesItems" :key="series._id">
                    <td>{{ series.seriesName }}</td>
                    <td class="text-right">
                        <v-btn icon @click="setMSType('edit'),MSDetails(series._id)" x-small>
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn icon x-small @click="removeSeries(series._id)">
                            <v-icon class="red--text">mdi-delete</v-icon>
                        </v-btn>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
    </v-card>
</template>
<script>
import Vue from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
    computed: {
        ...mapGetters([
            "getSeries",
            "getSeriesItems",
            "getSeriesErrors",
            "getSeriesType"
        ])
    },
    methods: {
        ...mapMutations(["setMSType", "clearMS"]),
        ...mapActions([
            "createSeries",
            "fetchSeries",
            "openSecondModal",
            "MSDetails",
            "MSUpdate"
        ]),
        removeSeries(SID) {
            this.openSecondModal({
                width: 500,
                open: true,
                data: { SID },
                type: "dictionary_model_series"
            });
        }
    },
    beforeMount() {
        this.fetchSeries();
    }
};
</script>
