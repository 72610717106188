<template>
  <v-container fluid>
    <v-row class="pa-4">
      <v-col cols="12" class="pa-0">
        <div class="central__title">
          <!-- === MAIN HEADER ===  -->
          <h1 class="font-weight-light ml-2">Słowniki</h1>
        </div>
      </v-col>

      <v-tabs :right="true" class="bg-dictionary">
        <v-tab>Słowniki</v-tab>
        <v-tab>Serwis</v-tab>
        <v-tab>Samochody</v-tab>

        <v-tab-item>
          <v-row>
            <v-col cols="12" md="12" lg="6" xl="4" class="dictionary">
              <TaskCategory />
            </v-col>
            <v-col cols="12" md="12" lg="6" xl="4" class="dictionary">
              <TaskPriority />
            </v-col>
            <v-col cols="12" md="12" lg="6" xl="4" class="dictionary">
              <UsersJob />
            </v-col>
            <v-col cols="12" md="12" lg="6" xl="4" class="dictionary">
              <UsersContract />
            </v-col>
            <v-col cols="12" md="12" lg="6" xl="4" class="dictionary">
              <UsersDayJob />
            </v-col>
            <v-col cols="12" md="12" lg="6" xl="4" class="dictionary">
              <UsersRole />
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <v-row>
            <v-col cols="12" md="12" lg="6" xl="4" class="dictionary">
              <ManufacturerBrand />
            </v-col>
            <v-col cols="12" md="12" lg="6" xl="4" class="dictionary">
              <ServiceType />
            </v-col>
            <v-col cols="12" md="12" lg="6" xl="4">
              <Price />
            </v-col>
            <v-col cols="12" md="12" lg="6" xl="4" class="dictionary">
              <Options />
            </v-col>
            <v-col cols="12" md="12" lg="6" xl="4" class="dictionary">
              <ModelSeries />
            </v-col>
            <v-col cols="12" md="12" lg="6" xl="4" class="dictionary">
              <ServiceCore />
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <v-row>
            <v-col cols="12" md="12" lg="6" xl="4" class="dictionary">
              <Cars />
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs>
    </v-row>
  </v-container>
</template>

<script>
import TaskCategory from "@/components/Admin/Dictionaries/TaskCategory.vue";
import TaskPriority from "@/components/Admin/Dictionaries/TaskPriority.vue";
import UsersJob from "@/components/Admin/Dictionaries/UsersJob.vue";
import UsersRole from "@/components/Admin/Dictionaries/UsersRole.vue";
import UsersContract from "@/components/Admin/Dictionaries/UsersContract.vue";
import UsersDayJob from "@/components/Admin/Dictionaries/UsersDayJob.vue";
import ManufacturerBrand from "../../components/Admin/Dictionaries/ManufacturerBrand";
import ServiceType from "../../components/Admin/Dictionaries/ServiceType";
import Cars from "../../components/Admin/Dictionaries/Cars";
import Price from "../../components/Admin/Dictionaries/Price";
import Options from "../../components/Admin/Dictionaries/Options";
import ModelSeries from "../../components/Admin/Dictionaries/ModelSeries";
import ServiceCore from "../../components/Admin/Dictionaries/ServiceCore";

export default {
  components: {
    TaskCategory,
    TaskPriority,
    UsersJob,
    UsersContract,
    UsersDayJob,
    ManufacturerBrand,
    ServiceType,
    Cars,
    Price,
    Options,
    ModelSeries,
    ServiceCore,
    UsersRole
  }
};
</script>
