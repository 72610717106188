<template>
  <v-card>
    <div class="px-4 pt-2 dictionary__header">
      <span class="title font-weight-light">Pracownicy: Role</span>
      <v-menu
        :close-on-content-click="false"
        max-width="500"
        v-model="getURole.open"
        @input="v => v || clearURole()"
      >
        <template v-slot:activator="{on}">
          <v-btn icon v-on="on" @click="setURoleType('create')">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <v-card>
          <p
            class="display-1 py-3 text-center ma-0 font-weight-light"
          >{{getURoleType === 'create' ? 'Dodawanie' : 'Edycja'}}</p>
          <v-divider></v-divider>
          <v-container fluid class="py-0">
            <v-row>
              <v-col md="12">
                <v-text-field
                  v-model="getURole.roleName"
                  :error-messages="getURoleErrors.roleName"
                  label="Nazwa"
                ></v-text-field>
              </v-col>
              <v-col md="3" offset-md="8">
                <v-btn
                  class="buttons buttons--add"
                  v-if="getURoleType == 'create'"
                  @click="createURole"
                >Dodaj 7</v-btn>
                <v-btn class="buttons buttons--add" @click="uRoleUpdate" v-else>Edytuj</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-menu>
    </div>
    <v-simple-table height="250" fixed-header>
      <thead>
        <tr>
          <th>Nazwa</th>
          <th>Akcje</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="role in getURoleItems" :key="role._id">
          <td>{{role.roleName}}</td>
          <td>
            <v-btn icon x-small @click="URoleDetails(role._id),setURoleType('edit')">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon x-small @click="deleteUserRole(role._id)">
              <v-icon class="red--text">mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-card>
</template>
<script>
import Vue from "vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  data: () => ({}),
  computed: {
    ...mapGetters([
      "getURole",
      "getURoleType",
      "getURoleItems",
      "getURoleErrors"
    ])
  },
  methods: {
    ...mapMutations(["setURoleType", "clearURole"]),
    ...mapActions([
      "createURole",
      "deleteURole",
      "fetchURole",
      "URoleDetails",
      "openSecondModal",
      "uRoleUpdate"
    ]),
    deleteUserRole(UID) {
      this.openSecondModal({
        width: 500,
        open: true,
        data: { UID },
        type: "dictionary_user_role"
      });
    }
  },
  beforeMount() {
    this.fetchURole();
  }
};
</script>